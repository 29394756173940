import React from "react";
import Contents from "../components/pages/home/Contents";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import styled from "styled-components";
import { Action, Fab } from "react-tiny-fab";
import DesignIcon from "../assets/images/ic_design.jpeg";
import MarkIcon from "../assets/images/ic_mark.jpeg";
import PatentIcon from "../assets/images/ic_patent.jpeg";

// const RoundedBtn = styled.div`
//   background-color: #000000;
//   border: none;
//   color: white;
//   padding: 20px;
//   text-align: center;
//   text-decoration: none;
//   display: flex;
//   font-size: 16px;
//   margin: 4px 2px;
//   border-radius: 50%;
//   position: fixed;
//   left: 75%;
//   bottom:100px;
//   button
// `;

const Home = () => {
  console.log(MarkIcon)
  return (
    <>
        <Header />
        <Contents />
        <Footer />      
      {/* <RoundedBtn onClick={()=>{
        window.open("https://chat.openai.com/g/g-f1bNjriB6-sangpyo-culweon-deungrog", "_blank", `toolbar=no,
        location=no,
        status=no,
        menubar=no,
        scrollbars=yes,
        resizable=yes,
        width=500px,
        height=800px`);
      }}>상표상담</RoundedBtn> */}
      {/* <Fab
        icon="AI챗봇"
        event="click"
      >
        <Action
          text="상표"
          style={{backgroundImage:`url(${MarkIcon})`, backgroundSize:"cover"}}
          onClick={()=>{
            window.open("https://chat.openai.com/g/g-f1bNjriB6-sangpyo-culweon-deungrog", "_blank", `toolbar=no,
            location=no,
            status=no,
            menubar=no,
            scrollbars=yes,
            resizable=no,
            width=500px,
            height=800px`);}}/>
          <Action
          text="디자인"
          style={{backgroundImage:`url(${DesignIcon})`, backgroundSize:"cover"}}
          onClick={()=>{
            window.open("https://chat.openai.com/g/g-QEGevqDmk-dijain-teugheo-eosiseuteonteu", "_blank", `toolbar=no,
            location=no,
            status=no,
            menubar=no,
            scrollbars=yes,
            resizable=no,
            width=500px,
            height=800px`);}}/>
           <Action
          text="특허"
          style={{backgroundImage:`url(${PatentIcon})`, backgroundSize:"cover"}}
          onClick={()=>{
            window.open("https://chat.openai.com/g/g-2nBY0mLqJ-teugheo-sangdam-doumi", "_blank", `toolbar=no,
            location=no,
            status=no,
            menubar=no,
            scrollbars=yes,
            resizable=no,
            width=500px,
            height=800px`);}}/>
          
      </Fab>
       */}
    </>
  );
};

export default Home;
