export const dummyAttorney = [
  {
    attorneyName: "손*남",
    attorneyLocation: "서울",
    priceContent: [
      {
        title: "임시출원 진행",
        price: "25만원",
      },
      {
        title: "정규출원 진행",
        price: "175만원",
      },
    ],
    solutionContent: [
      {
        title: "문제 상황",
        content: "아이디어를 누군가 모방하여 특허 출원할까 두려웠습니다.",
      },
      {
        title: "해결 방향",
        content: "임시 출원을 통해 아이디어를 보호하고, 특허 출원을 진행하였습니다.",
      },
    ],
  },
  {
    attorneyName: "김호*",
    attorneyLocation: "서울/경기",
    priceContent: [
      {
        title: "임시출원 진행",
        price: "35만원",
      },
      {
        title: "정규출원 진행",
        price: "195만원",
      },
    ],
    solutionContent: [
      {
        title: "문제 상황",
        content: "연구 일지를 바탕으로 특허를 제출해야 합니다.",
      },
      {
        title: "해결 방향",
        content: "전문 연구에 특화된 특허 가이드를 제공 받아 안전하게 권리를 보호 받았습니다.",
      },
    ],
  },
  {
    attorneyName: "박*지",
    attorneyLocation: "서울",
    priceContent: [
      {
        title: "임시출원 진행",
        price: "30만원",
      },
      {
        title: "정규출원 진행",
        price: "185만원",
      },
    ],
    solutionContent: [
      {
        title: "문제 상황",
        content: "상표 출원을 위한 상품 분류를 정확히 알 수 없었습니다.",
      },
      {
        title: "해결 방향",
        content: "전문가의 상품 분류 도움을 받아 정확한 상품 분류를 확인하고 출원하였습니다.",
      },
    ],
  },
  {
    attorneyName: "김*민",
    attorneyLocation: "서울",
    priceContent: [
      {
        title: "임시출원 진행",
        price: "28만원",
      },
      {
        title: "정규출원 진행",
        price: "200만원",
      },
    ],
    solutionContent: [
      {
        title: "문제 상황",
        content: "특허 출원을 위한 기술적인 부분을 이해하기 어려웠습니다.",
      },
      {
        title: "해결 방향",
        content: "전문가의 기술적인 부분 설명을 받아 출원을 진행하였습니다.",
      },
    ],
  },
  {
    attorneyName: "이*희",
    attorneyLocation: "서울",
    priceContent: [
      {
        title: "임시출원 진행",
        price: "32만원",
      },
      {
        title: "정규출원 진행",
        price: "195만원",
      },
    ],
    solutionContent: [
      {
        title: "문제 상황",
        content: "디자인을 특허로 보호하기 위한 방법을 모르겠습니다.",
      },
      {
        title: "해결 방향",
        content: "디자인 특허 출원을 위한 전문가의 도움을 받아 안전하게 출원하였습니다.",
      },
    ],
  },
];
