import React from "react";
import Contents from "../components/pages/join/Contents";
import Header from "../components/header/Header";

const Join = () => {
  return (
    <>
      <Header />
      <Contents />
    </>
  );
};

export default Join;
