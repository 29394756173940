import React from "react";
import "../../assets/scss/modal.scss";
import AgentInformationModal from "../modals/AgentInformationModal";
import AlertModal from "../modals/AlertModal";
import LoadingModal from "../modals/LoadingModal";
import PaymentModal from "../modals/PaymentModal";
import PreMatchingModal from "../modals/PreMatchingModal";
import QuotationDetailModal from "../modals/QuotationDetailModal";
import QuotationModal from "../modals/QuotationModal";
import RightMenuModal from "../modals/RightMenuModal";
import SimplelistModal from "../modals/SimpleListModal";
import SimpleRightModal from "../modals/SimpleRightModal";
import TwoButtonModal from "../modals/TwoButtonModal";

const ModalProvider = () => {
  return (
    <>
      <AlertModal />
      <LoadingModal />
      <RightMenuModal />
      <SimpleRightModal />
      <PreMatchingModal />
      <AgentInformationModal />
      <QuotationModal />
      <QuotationDetailModal />
      <SimplelistModal />
      <TwoButtonModal />
      <PaymentModal />
    </>
  );
};

export default ModalProvider;
