import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import Details from "../components/pages/notice/Details";

const NoticeDetail = () => {   
    return (
        <>
            <Header />
            <Details />
            <Footer />
        </>
    );
}
export default NoticeDetail;